import React from 'react'

import { Query } from '@apollo/react-components'
import SizeChartTable from './SizeChartTable'
import gql from 'graphql-tag'
import CircularProgress from '@material-ui/core/CircularProgress'

const GET_BRAND_SIZES = gql`
    query Sizes($brandnamedatabase: String! $humanType: String!) {
        sizes( where:{brand:{name:$brandnamedatabase} humanType:{name:$humanType}}  orderBy:heightMin_ASC ){
            brand {
                name
                displayName
            }
            name
            nameUS
            heightMin
            heightMax
            weightMin
            weightMax
            chestMin
            chestMax
            waistMin
            waistMax
            hipsMin
            hipsMax
            neck
            heightMinInch
            heightMaxInch
            weightMinInch
            weightMaxInch
            chestMinInch
            chestMaxInch
            waistMinInch
            waistMaxInch
            hipsMinInch
            hipsMaxInch
            neckInch
            humanType{name}
        }
    }
`
const RenderSizeChartTable = props => {

  const { brandnamedatabase, humanType, activeScale, brandname,actLang } = props

  return (
    <Query query={GET_BRAND_SIZES} variables={{ brandnamedatabase, humanType: humanType }}>
      {({ loading, error, data }) => {
        if (loading) return <CircularProgress style={{ margin: 5 }} color="primary"/>
        if (error) return `Error! ${error.message}`
        return (
          <SizeChartTable
            brandname={brandname}
            activeScale={activeScale}
            chartSizes={data.sizes}
            ht={humanType}
            logo={props.logo}
            actLang={actLang}
          />
        )
      }}
    </Query>


  )
}

export default (RenderSizeChartTable)

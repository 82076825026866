import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { graphql } from 'gatsby'
import Footer from '../../components/Footer/Footer'
import Section from '../../components/Section/Section'
import { CACHE_STATE } from '../../apollo/gql/CACHE_STATE'
import withStyles from '@material-ui/core/styles/withStyles'
import RenderSizeChartTable from './RenderSizeChartTable'
import PageHeaderFullHalf from '../../components/PageHeaderFullHalf/PageHeaderFullHalf'
import HeaderProvider from '../../components/Header/HeaderProvider'
import BannerWithImg from "../../elements/BannerWithImg/BannerWithImg";
import InstagramSection from "../../components/InstagramSection/InstagramSection";
import SpainHolidaysCta from "../../elements/SpainholidaysCta/SpainHolidaysCta";
import RecentBlogPosts from "../../elements/RecentBlogPosts/RecentBlogPosts";
import SizeChartTable from './SizeChartTable'
import SEO from '../../components/SEO'

const styles = {
  root: {},
  tabs: {
    transition: 'all 0.4s ease',
  },
}


const BrandSizeChart = (props) => {


  // handleChange = (event, value) => {
  //   if (value === 0) {
  //     this.setState({
  //       activeScale: 'CM',
  //     })
  //   } else if (value === 1) {
  //     this.setState({
  //       activeScale: 'INCH',
  //     })
  //   }
  // }


  const { classes } = props
  const getData = props.data
  const { locale } = props.pageContext
  const modified = []

  getData.datoCmsBrand._allUrlLocales.map(item => {
    if(item.locale ==="de"){
      modified.push({ 'locale': item.locale, 'value': "groessentabellen/" + item.value })
    }
    if(item.locale ==="en"){
      modified.push({ 'locale': item.locale, 'value': "sizecharts/" + item.value })
    }
  })

  // const getInfoString = locale === 'de' ? data.a1main.brand.infoDE : data.a1main.brand.info
  const sizechart = locale === 'de' ? 'Neoprenanzug Größentabellen in Cm und Inch, für Männer, Frauen und Kinder'
    : 'Wetsuit Size Charts in Cm and Inch, for Men, Women and Kids'

  return (
    <div className={classes.root}>
      <SEO
        seoMetaTags={getData.datoCmsBrand.seoMetaTags.tags}
        actLang={locale}
        localURLS={modified}
      />
      <HeaderProvider
        localURLS={modified}
        actLang={locale}
        absolute
        light
        SEOtitle={props.pageContext.brandname}
        SEOdescription={sizechart}
        SEOimage={getData.datoCmsBrand.image.fluid.src}
        SEOpathname={getData.datoCmsBrand.url}
        seoMetaTags={getData.datoCmsBrand.seoMetaTags.tags}
      />
      <PageHeaderFullHalf
        image={getData.datoCmsSizeChart.headerImage.fluid}
        title={getData.datoCmsBrand.brandname}
        subtitle={sizechart}

        // alt={data.datoCmsBrand.image.alt}
      />

      <Section smallLeftRightPadding paddingTop={40}>

        <Query query={CACHE_STATE}>
          {({ loading, error, data }) => {
            if (loading) return
            if (error) return `Error! ${error.message}`
            return (
              <>
                {/*<RenderSizeChartTable*/}
                {/*  brandname={props.pageContext.brandname}*/}
                {/*  brandnamedatabase={props.pageContext.brandNameDatabase}*/}
                {/*  humanType={'MEN'}*/}
                {/*  activeScale={data.activeScaleSizecharts}*/}
                {/*  logo={getData.datoCmsBrand.image.fluid}*/}
                {/*  actLang={locale}*/}
                {/*/>*/}
                {/*<RenderSizeChartTable*/}
                {/*  brandname={props.pageContext.brandname}*/}
                {/*  brandnamedatabase={props.pageContext.brandNameDatabase}*/}
                {/*  humanType={'WOMEN'}*/}
                {/*  activeScale={data.activeScaleSizecharts}*/}
                {/*  logo={getData.datoCmsBrand.image.fluid}*/}
                {/*  actLang={locale}*/}
                {/*/>*/}
                {/*<RenderSizeChartTable*/}
                {/*  brandname={props.pageContext.brandname}*/}
                {/*  brandnamedatabase={props.pageContext.brandNameDatabase}*/}
                {/*  humanType={'KIDS'}*/}
                {/*  activeScale={data.activeScaleSizecharts}*/}
                {/*  logo={getData.datoCmsBrand.image.fluid}*/}
                {/*  actLang={locale}*/}
                {/*/>*/}
                <SizeChartTable
                  brandname={props.pageContext.brandname}
                  activeScale={data.activeScaleSizecharts}
                  chartSizes={getData.men.sizes}
                  ht={'MEN'}
                  logo={getData.datoCmsBrand.image.fluid}
                  actLang={locale}
                /><SizeChartTable
                  brandname={props.pageContext.brandname}
                  activeScale={data.activeScaleSizecharts}
                  chartSizes={getData.women.sizes}
                  ht={'WOMEN'}
                  logo={getData.datoCmsBrand.image.fluid}
                  actLang={locale}
                /><SizeChartTable
                  brandname={props.pageContext.brandname}
                  activeScale={data.activeScaleSizecharts}
                  chartSizes={getData.kids.sizes}
                  ht={'KIDS'}
                  logo={getData.datoCmsBrand.image.fluid}
                  actLang={locale}
                />
              </>
            )
          }}
        </Query>

      </Section>
        <BannerWithImg
            title={getData.datoCmsHome.bannerWithBackgroundImage[0].title}
            text={getData.datoCmsHome.bannerWithBackgroundImage[0].text}
            image={getData.datoCmsHome.bannerWithBackgroundImage[0].image}
            buttonTitle={getData.datoCmsHome.bannerWithBackgroundImage[0].buttonTitle}
            link={getData.datoCmsHome.bannerWithBackgroundImage[0].buttonLink.url}
            actLang={props.pageContext.locale}
        />
        <RecentBlogPosts
            articles={getData.allDatoCmsArticle.nodes}
            actLang={props.pageContext.locale}
            whitebg
        />
      <Footer
        actLang={locale}
      />
    </div>
  )

}
export default withStyles(styles)(BrandSizeChart)

export const query = graphql`
    query($locale: String! $brandNameDatabase: String!) {
        datoCmsBrand(brandNameDatabase:{eq:$brandNameDatabase} locale:{eq:$locale}){
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            brandname
            meta{createdAt}
            subtitle
            brandNameDatabase
            url
            image{
                alt
                fluid(maxWidth:705){
                    src
                    ...GatsbyDatoCmsFluid_noBase64

                }
            }

        }

        datoCmsSizeChart   {
            headerImage{
                alt
                fluid(maxWidth:1800){
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }


        }

        datoCmsHome(locale:{eq:$locale}) {
          
        
            bannerWithBackgroundImage{
                title
                text
                image{
                    alt
                    fluid(maxWidth:1800){
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
                buttonTitle
                buttonLink{
                    ...on DatoCmsSizeFinder{
                        url
                    }
                    ...on DatoCmsWetsuitFinder{
                        url
                    }

                }
            }
            bannerBlank{
                title
                subtitle
                buttonLink
                buttonTitle
            }
        }
        allDatoCmsArticle(
            sort:{
                fields:meta___updatedAt
                order:DESC
            }
            limit:6
            filter:{
                meta:{status:{eq:"published"}  }
                locale:{eq:$locale}
            }
        ){
            nodes{
                locale
                url
                title
                meta{
                    updatedAt(formatString: "MM-DD-YYYY")
                }
                summary
                featuredImage{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
            }
        }
        kids: a1main{
            sizes(
                orderBy:heightMin_ASC
                where:{brand:{name:$brandNameDatabase} humanType:{name:"KIDS"}}){
                brand {
                    name
                    displayName
                }
                name
                nameUS
                heightMin
                heightMax
                weightMin
                weightMax
                chestMin
                chestMax
                waistMin
                waistMax
                hipsMin
                hipsMax
                neck
                heightMinInch
                heightMaxInch
                weightMinInch
                weightMaxInch
                chestMinInch
                chestMaxInch
                waistMinInch
                waistMaxInch
                hipsMinInch
                hipsMaxInch
                neckInch
                humanType{name}
            }
        }
       men: a1main{
            sizes(
                orderBy:heightMin_ASC
                where:{brand:{name:$brandNameDatabase} humanType:{name:"MEN"}}){
                brand {
                    name
                    displayName
                }
                name
                nameUS
                heightMin
                heightMax
                weightMin
                weightMax
                chestMin
                chestMax
                waistMin
                waistMax
                hipsMin
                hipsMax
                neck
                heightMinInch
                heightMaxInch
                weightMinInch
                weightMaxInch
                chestMinInch
                chestMaxInch
                waistMinInch
                waistMaxInch
                hipsMinInch
                hipsMaxInch
                neckInch
                humanType{name}
            }
        }
        women: a1main{
            sizes(
                orderBy:heightMin_ASC
                where:{brand:{name:$brandNameDatabase} humanType:{name:"WOMEN"}}){
                brand {
                    name
                    displayName
                }
                name
                nameUS
                heightMin
                heightMax
                weightMin
                weightMax
                chestMin
                chestMax
                waistMin
                waistMax
                hipsMin
                hipsMax
                neck
                heightMinInch
                heightMaxInch
                weightMinInch
                weightMaxInch
                chestMinInch
                chestMaxInch
                waistMinInch
                waistMaxInch
                hipsMinInch
                hipsMaxInch
                neckInch
                humanType{name}
            }
        }
    }
`
